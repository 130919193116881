<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="minWidth"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${modalIconName}`" />
        </span>
        <span>{{ modalHeader }}</span>
      </div>
    </header>
    <section class="modal-card-body">
      <div v-if="isActive && !isExportTextModalActive && !isEmailModalActive">
        <div class="field">
          <span>Select export type</span>
        </div>
        <div class="field">
          <div class="pretty p-default p-round p-bigger p-smooth">
            <input type="radio"
              id="text"
              value="text"
              v-model="exportType">
            <div class="state p-primary-o">
              <label>Email Text</label>
            </div>
          </div>
          <div class="pretty p-default p-round p-bigger p-smooth">
            <input type="radio"
              :disabled="value.subQuoteNo !== 0"
              id="text"
              value="arnie"
              v-model="exportType">
            <div class="state p-primary-o">
              <label>Arnie</label>
            </div>
          </div>
          <div class="pretty"
            v-if="value.subQuoteNo !== 0">
            <span class="has-text-danger">Please go to main quote to send to ARNIE</span>
          </div>

          <!-- <input class="is-checkradio"
            id="text"
            type="radio"
            name="text"
            value="text"
            v-model="exportType">
          <label for="text">Email Text</label>
          <input class="is-checkradio"
            id="arnie"
            type="radio"
            name="arnie"
            value="arnie"
            v-model="exportType">
          <label for="arnie">Arnie</label> -->
        </div>
      </div>
      <quote-export-text v-if="isExportTextModalActive"
        :active.sync="isExportTextModalActive"
        v-model="value"
        @generate-text-email="generateTextEmail"
        ref="exporttext" />
      <vue-email v-if="isEmailModalActive"
        v-show="!isImageAttachActive"
        ref="emailer"
        v-model="emailer"
        :report-bytes="reportBytes"
        :report-name="`${emailer.reportName}.${reportExtension}`"
        :report-content-type="reportContentType"
        :quote-image-attachments="selectedImages"
        @new-email="newEmailInput"
        @sent="sent"
        :asset-id="emailer.assetId" />
      <image-select v-if="emailer && isImageAttachActive"
        v-show="isImageAttachActive"
        v-model="selectedImages"
        :quote-id="emailer.assetId"
        :sub-quote-nos="value.subQuoteNos"
        :quote-ids="value.subQuoteNos.map(q => q.value)" />
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <div v-if="isActive && !isExportTextModalActive && !isEmailModalActive">
        <button class="button is-success tooltip"
          @click="exportQuote()"
          data-tooltip="Export">
          <span class="icon">
            <i class="mdi mdi-file-export mdi-18px" />
          </span>
          <span>Export</span>
        </button>
        <button class="button tooltip"
          @click="close()"
          data-tooltip="Close">Close</button>
      </div>
      <div v-if="isExportTextModalActive">
        <button class="button is-success tooltip"
          @click="ok()"
          data-tooltip="Email Text Quote">
          <span class="icon is-small">
            <i class="mdi mdi-18px mdi-send" />
          </span>
          <span>Ok</span>
        </button>
        <button class="button tooltip"
          @click="close()"
          data-tooltip="Close">Close</button>
      </div>
      <div v-if="isEmailModalActive">
        <div style="align-self: flex-start;">
          <button v-if="false"
            class="button tooltip is-tooltip-topleft"
            @click="attach()"
            data-tooltip="Attach media">
            <span class="icon">
              <i class="mdi mdi-paperclip mdi-18px" />
            </span>
          </button>
        </div>
        <div style="align-self: flex-end;">
          <button class="button is-primary tooltip is-tooltip-topright"
            @click="send()"
            data-tooltip="Send Email"
            :disabled="!enableSend">
            <span class="icon">
              <i class="mdi mdi-send mdi-18px" />
            </span>
            <span>Send</span>
          </button>
          <button class="button is-primary tooltip"
            v-show="enableAttach"
            @click="showAttachImage()"
            :data-tooltip="[!toggleAttachButton ? 'Attach images' : 'Done']"
            :disabled="!enableAttach">
            <span v-if="!toggleAttachButton"
              class="icon is-small">
              <i class="mdi mdi-18px mdi-paperclip" />
            </span>
            <span v-else>Done</span>
          </button>
          <button class="button tooltip is-tooltip-topright"
            @click="discardEmail()"
            data-tooltip="Discard Email">
            <span class="icon">
              <i class="mdi mdi-delete mdi-18px" />
            </span>
          </button>
        </div>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import StoreUtil from '@/store/utils'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import Guid from '@/components/Guid'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { Emailer } from '@/classes'
import { RemarkTypes } from '@/enums'
import mimeTypes from 'mime-types'
import ImageSelect from '@/components/ImageSelect'
import QuoteService from '../QuoteService'
import QuoteExportText from './QuoteExportText'
import { VueEmail } from '@/components/VueEmail'
import PrintPreviewStoreMixin from '@/components/printpreview/storeMixin'
import PrintPreviewService from '@/components/printpreview/PrintPreviewService'
import { AssetTypes, EmailAssetTypes } from '@/enums'

// Temporary const for copyright message
const divider = '=============================================================='
const copyright =
  'All accompanying materials are the exclusive property of [COMPANY].  All [COMPANY] rights are reserved.  These Materials must not be reproduced, adapted, modified, published, or communicated to any person in any form, without express prior written consent of [COMPANY].  Any unauthorised use of these Materials will be vigorously pursued and may result in legal action, including a claim for damages or an account for loss of profits.'

export default {
  name: 'QuoteExportModal',
  components: {
    BaseModalEx,
    ImageSelect,
    VueEmail,
    QuoteExportText
  },
  mixins: [PrintPreviewStoreMixin, NumberFiltersMixin],
  props: {
    value: null,
    vehicle: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-file-export'
    },
    enableAttach: {
      type: Boolean,
      default: true
    },
    defaultEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: false,
      isEmailModalActive: false,
      isExportTextModalActive: false,
      toggleAttachButton: false,
      exportType: 'text',
      modalHeader: 'Export Types',
      minWidth: 30,
      reportOptions: [0, 0, 0, 0, 0, 0],
      reportBytes: null,
      reportExtension: 'txt',
      reportContentType: '',
      emailer: null,
      isEnteringNewEmail: false,
      isImageAttachActive: false,
      selectedImageIds: [],
      // images: [],
      selectedImages: [],
      modalIconName: ''
    }
  },
  computed: {
    enableSend() {
      return this.emailer.toEmails.length > 0 && !this.isEnteringNewEmail && !this.isImageAttachActive
    }
  },
  watch: {
    // $userInfo: function(newVal, oldVal) {
    //   console.log('watch $userInfo', oldVal)
    //   if (oldVal === null) {
    //     this.resetEmailer()
    //     // this.loadReport()
    //   }
    // },
    'emailer.attachments': {
      handler: function (newVal, oldVal) {
        if (this.selectedImages.length > 0) {
          const difference = this.selectedImages.filter(function (image) {
            const index = newVal.findIndex((i) => i === image.fileName)
            return index === -1
          })
          if (!_isEmpty(difference)) {
            const imageIndex = this.selectedImages.findIndex((i) => i.fileName === difference[0].fileName)
            this.selectedImages.splice(imageIndex, 1)
            const idIndex = this.selectedImageIds.findIndex((id) => id === difference[0].quoteImageId)
            this.selectedImageIds.splice(idIndex, 1, Guid.empty())
          }
        }
      },
      deep: true
    },
    selectedImageIds: {
      handler: function (newVal, oldVal) {
        // console.log(newVal)
        // this.updateAttachmentList(newVal)
      }
    }
  },
  created() {
    this.modalIconName = this.iconName
  },
  mounted() {
    this.isActive = this.active || false
    // this.resetEmailer()
  },
  methods: {
    async exportQuote() {
      if (this.exportType == 'text') {
        this.modalIconName = 'mdi-file-document'
        this.modalHeader = 'Quote Report Options'
        this.minWidth = 30
        this.reportExtension = 'txt'
        this.isExportTextModalActive = true
      }
      if (this.exportType == 'arnie') {
        this.$showSpinner
        try {
          await QuoteService.validateQuote(this.value.quoteId)
          this.reportExtension = 'xml'
          await this.generateArnieEmail()
        } catch {
          this.$emit('close')
        } finally {
          this.$hideSpinner
        }
      }
    },
    close() {
      this.$emit('close')
    },
    ok() {
      this.$refs.exporttext.generateReportOptions()
    },
    async generateTextEmail(reportOptions) {
      this.reportOptions = reportOptions
      this.$showSpinner()

      this.reportContentType = mimeTypes.lookup(this.reportExtension)
      const sessionEmailer = this.retrieveEmailer() || StoreUtil.getLocalStorage(this.$route.query.emailerId, 'emailer')
      // console.log(sessionEmailer)
      if (sessionEmailer) {
        this.emailer = sessionEmailer
      } else {
        this.emailer = _cloneDeep(this.getEmailer(this.$route.query.emailerId) || new Emailer())
      }

      this.emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
      // let subQuoteNo = this.value.subQuoteNo == 0 ? '' : '_' + this.value.subQuoteNo
      const emailSubject = this.value.claimNo != null && this.value.claimNo != '' ? `Quote Claim No: ${this.value.claimNo}` : 'Quote'
      this.emailer.subject = emailSubject
      this.emailer.message = `\n\n${divider}\n${copyright.replace(/\[COMPANY\]/g, this.$userInfo.companyName)}`
      // this.emailer.reportName = `${this.value.quoteNo}` + subQuoteNo
      this.emailer.reportName = `${this.value.prefix}${this.value.quoteNo}${this.value.suffix}${this.value.subQuoteNo !== 0 ? '_' + this.value.subQuoteNo : ''}`
      this.emailer.assetId = this.value.quoteId
      this.emailer.assetType = EmailAssetTypes.Quote
      this.emailer.remark = emailSubject
      this.emailer.remarkTypeId = RemarkTypes.OtherSystemRemark
      this.updateAttachmentList()
      this.$hideSpinner()
      this.isExportTextModalActive = false
      this.isEmailModalActive = true
      this.modalIconName = 'mdi-email-open'
      this.modalHeader = 'Send Email'
      this.minWidth = 60
    },
    async generateArnieEmail() {
      this.$showSpinner()
      this.reportContentType = mimeTypes.lookup(this.reportExtension)
      const sessionEmailer = this.retrieveEmailer() || StoreUtil.getLocalStorage(this.$route.query.emailerId, 'emailer')
      // console.log(sessionEmailer)
      if (sessionEmailer) {
        this.emailer = sessionEmailer
      } else {
        this.emailer = _cloneDeep(this.getEmailer(this.$route.query.emailerId) || new Emailer())
      }

      // const defaultEmail = this.insurer.emails.filter(e => e.isDefault)
      if (this.defaultEmail) {
        this.emailer.toEmails.splice(this.emailer.toEmails.length, 1, this.defaultEmail)
      }

      var monthYearBuild = this.vehicle.buildYear
      if (this.vehicle.buildMonth !== null) {
        var buildMonth = this.$options.filters.pad(this.vehicle.buildMonth, 2)
        monthYearBuild = buildMonth + '/' + monthYearBuild
      }

      const companyPhone = await QuoteService.getCompanyPhone()
      const messageBody =
        'Contact Telephone: ' +
        companyPhone +
        '\n' +
        'Claim No: ' +
        this.value.claimNo +
        '\n' +
        'Rego: ' +
        this.vehicle.rego +
        '\n' +
        'Make: ' +
        this.vehicle.model.make +
        '\n' +
        'Model: ' +
        this.vehicle.model.model +
        '\n' +
        'Year: ' +
        monthYearBuild

      this.emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
      const emailSubject = this.value.invoices.filter((i) => i.invoiceType !== 2).length > 0 ? 'Invoice' : 'Quote'
      this.emailer.subject = emailSubject
      this.emailer.message = `\n${messageBody}\n\n${divider}\n${copyright.replace(/\[COMPANY\]/g, this.$userInfo.companyName)}`
      this.emailer.reportName = `${this.value.prefix}${this.value.quoteNo}${this.value.suffix}`
      this.emailer.assetId = this.value.quoteId
      this.emailer.assetType = EmailAssetTypes.Quote
      this.emailer.remark = emailSubject
      this.emailer.remarkTypeId = RemarkTypes.OtherSystemRemark
      this.updateAttachmentList()
      this.$hideSpinner()
      this.isExportTextModalActive = false
      this.isEmailModalActive = true
      this.modalIconName = 'mdi-email-open'
      this.modalHeader = 'Send Email'
      this.minWidth = 60
    },
    async send() {
      if (this.exportType == 'arnie') {
        await this.$refs.emailer.sendExport('arnie', '0')
      } else {
        await this.$refs.emailer.sendExport('text', this.reportOptions)
      }
    },
    discardEmail() {
      this.isEmailModalActive = false
      this.isImageAttachActive = false
      this.selectedImageIds.splice(0, this.selectedImageIds.length)
      this.selectedImages.splice(0, this.selectedImages.length)
      // this.resetEmailer()
      this.$emit('close')
    },
    attachMedia() {},
    async resetEmailer() {
      const sessionEmailer = this.retrieveEmailer() || StoreUtil.getLocalStorage(this.$route.query.emailerId, 'emailer')
      // console.log(sessionEmailer)
      if (sessionEmailer) {
        this.emailer = sessionEmailer
      } else {
        this.emailer = _cloneDeep(this.getEmailer(this.$route.query.emailerId) || new Emailer())
      }
      this.emailer.message = `\n\n${divider}\n${copyright.replace(/\[COMPANY\]/g, this.$userInfo.companyName)}`
      StoreUtil.removeLocalStorage(this.$route.query.emailerId, 'emailer')
      this.persistEmailer()
      this.updateAttachmentList()
    },
    retrieveEmailer() {
      return JSON.parse(sessionStorage.getItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`))
    },
    persistEmailer() {
      sessionStorage.setItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`, JSON.stringify(this.emailer))
    },
    clearSessionStorage() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|${this.$route.meta.ekey}`)
    },
    sent() {
      // 'sent' event from VueEmail component. Create email remark for asset e.g. QuickInvoice etc
      if (this.emailer.assetId && this.emailer.assetId !== Guid.empty()) {
        const remarkText = `${this.emailer.remark} has been emailed to ${this.emailer.toEmails.join(', ')}`
        const remark = {
          remarkId: Guid.empty(),
          assetId: this.emailer.assetId,
          remarks: remarkText,
          remarkTypeId: this.emailer.remarkTypeId
        }
        // console.log(remark)
        PrintPreviewService.postRemark(remark)
      }
      this.discardEmail()
    },
    newEmailInput(newEmail) {
      this.isEnteringNewEmail = newEmail
    },
    async showAttachImage() {
      this.isImageAttachActive = !this.isImageAttachActive
      this.toggleAttachButton = !this.toggleAttachButton
      if (!this.isImageAttachActive) {
        this.updateAttachmentList()
      }
    },
    updateAttachmentList(imagesForSend) {
      this.emailer.attachments.splice(0, this.emailer.attachments.length)
      this.emailer.attachments.splice(this.emailer.attachments.length, 1, `${this.emailer.reportName}.${this.reportExtension}`)
      for (var i = 0; i < this.selectedImages.length; i++) {
        this.emailer.attachments.splice(this.emailer.attachments.length, 1, this.selectedImages[i].fileName)
      }
    },
    compressQuery() {
      return this.$route.query.query
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
